<template>
  <div class="statement-detail">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "账单明细",
        isShowBack: true,
        search: [
          {
            key: "reconState",
            label: "对账状态",
            type: "select",
            opt: {
              list: [
                {
                  label: "正常",
                  value: 1
                },
                {
                  label: "异常",
                  value: 2
                },
                {
                  label: "已冲正",
                  value: 3
                }
              ]
            }
          }
        ],
        // buttons: [
        //   {
        //     type: 1,
        //     name: '冲正',
        //     on(data) {
        //       if (data.data.reconState == 2) {
        //         _this.$router.push("/main/statement/edit/edit/" + data.data.orderNo);
        //       } else {
        //         _this.$message({
        //           showClose: true,
        //           message: "该订单正常无需冲正",
        //           type: "warning"
        //         });
        //       }
        //     }
        //   }
        // ],
        columns: [
          {
            label: "订单编号",
            key: "orderNo",
            // on: row => {
            //   if (row.reconState == 3) {
            //     _this.$router.push("/main/statement/order/show/" + row.orderNo);
            //   } else {
            //     _this.$message({
            //       showClose: true,
            //       message: "该订单无冲正记录"
            //     });
            //   }
            // }
          },
          { label: "交易编号", key: "transactionNo" },
          { label: "到账时间", key: "payDate" },
          { label: "对账时间", key: "reconDate" },
          { label: "订单金额", key: "orderAmount" },
          { label: "到账金额(元)", key: "payAmount" },
          {
            label: "对账状态",
            key: "reconStateStr",
            getClass(item) {
              return item.reconState == 2 ? "danger" : "";
            }
          }
        ]
      }
    };
  },
  mounted() {
    console.log("statement-detail created!!");
  },
  methods: {
    onGet(opt) {
      this.opt.title = "账单明细(" + this.$route.query.date + ")";
      let dto = {
        pageNumber: opt.skip,
        pageSize: opt.limit,
        reconDate: this.$route.query.date,
        reconState: opt.searchForm.reconState
      };
      this.post("/finance-service/leaseOrder/searchPageReconOrder", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.reconDate = this.format(item.reconDate);
          item.payDate = this.format(item.payDate);
          item.reconStateStr = ["正常", "异常", "已冲正"][item.reconState - 1];
          if (item.reconState == 2) {
            item.bk = 3;
          }
        }
        opt.cb(res.data);
      });
    }
  }
};
</script>